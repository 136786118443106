<!--  -->
<template>
    <el-dialog :visible.sync="show" title="排行榜" :close-on-click-modal="false" width="550px">
        <div>
            <div class="list-box">
                <div class="list-item" v-for="(item, index) in list" :key="index" @click="checkItem(item)">
                    <div class="fz26 tac">{{ item.rank }}：{{ item.studentUserName }} {{ item.score }}分</div>
                </div>
            </div>

            <div v-if="total == 0" class="no_data">
                <div class="empty">
                    <i class="iconfont icon-queshengye_zanwushuju"></i>
                </div>
                <p class="tc">暂无数据</p>
            </div>

            <div class="page_part">
                <el-pagination v-show="total > 0" :current-page="query.pageIndex" :page-size="query.pageSize" :total="total"
                    @current-change="handleCurrentChange" />
            </div>
        </div>

    </el-dialog>
</template>
<script>
export default {
    components: {},
    props: {},
    data () {
        return {
            show: false,
            query: {
                id: this.$route.query.id,
                status: "",
                groupId: "",
                studentUserName: "",
                queryType: 2,

                pageIndex: 1,
                pageSize: 20
            },

            total: 0,
            list: [],
        };
    },
    computed: {},
    watch: {},
    created () { },
    mounted () { },
    methods: {
        showDialog () {
            this.show = true;

            this.reLoadList();
        },
        handleCurrentChange (val) {
            this.query.pageIndex = val;
            this.getList();
        },
        getList () {
            const { query } = this;
            this.$ajax.post("/competition/queryAnalyseList", query).then(res => {
                this.list = res.list;
                this.total = res.total;
            });
        },
        reLoadList () {
            this.query.pageIndex = 1
            this.getList();
        },
    },
};
</script>
<style lang="scss" scoped>
.list-box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 20px;

    .list-item {
        width: 200px;
        margin-right: 18px;
        margin-bottom: 10px;
    }
}

.bg-img {
    background-position: center center;
    background-size: cover;
}

.no_data {
    text-align: center;

    .empty {
        margin-top: 60px;
        margin-bottom: 24px;

        i {
            font-size: 130px;
            color: #999;
        }
    }

    p {
        font-size: 16px;
        color: #999999;
        margin-bottom: 139px;
    }
}
</style>
